/* contactForm.css */

.form-container {
  max-width: 90%;
  width: 100%;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .form-container {
    padding: 0 10px;
  }

  .MuiGrid-item {
    width: 100%;
  }
}