/* Global styles */
body {
  overflow-x: hidden;
}

/* Menu styles */
.MuiMenu-paper {
  max-width: 100vw; /* Ensure menu does not exceed viewport width */
  overflow-x: hidden; /* Hide any overflow */
}

.navbar {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background-color: white;
  padding: 0;
  width: 100%;  /* Ensure the navbar takes up 100% of the viewport */
  box-sizing: border-box;  /* Include padding and border in the element's total width and height */
  overflow-x: hidden;  /* Prevent horizontal overflow */
  position: relative;
}

.navbar-logo {
  background-size: cover;
  background-position: center;
  margin-left: 20%;
  max-width: 100%;
}

.navbar-links {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.navbar-button {
  margin: 0 16px !important;
  color: #8faa30 !important;
  text-transform: capitalize !important;
  font-size: large !important;
}

.navbar-contact {
  background-color: #8faa30;
  color: white;
  border-radius: 32px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;
  max-height: 32px;
}

.MuiButtonBase-root.navbar-menu-button {
  display: none; /* Hide by default */
}

@media (max-width: 800px) {
  .navbar {
    flex-direction: row;
    height: auto;
    padding: 0;
    margin: 0;
    justify-content: space-between; /* Space items correctly */
    max-width: 100%; 
  }

  .navbar-logo {
    width: auto;
  }

  .navbar-contact {
    display: flex;
    align-items: center;
    justify-content: center; /* Center contact on mobile */
    padding: 4px 16px;
    margin-left: 0;
    width: 35%;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .navbar-contact .contact-text {
    font-size: 1rem; /* Smaller text to fit on one line */
  }

  .navbar-menu-button .MuiSvgIcon-root {
    font-size: 32px; /* Adjust icon size if needed */
    color: #8faa30; /* Match icon color with theme */
    /*margin-left: -64px;*/
  }

  .navbar-links {
    display: none;
  }

  .MuiButtonBase-root.navbar-menu-button {
    display: block;
  }
}

@media (min-width: 801px) {
  .navbar {
    flex-direction: row;
    height: auto;
    width: 100%;
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    justify-content: space-between; /* Space items correctly */
  }

  .navbar-logo {
    margin-left: 16px !important;
    width: auto;
  }

  .navbar-links {
    display: flex; /* Show links on tablets and larger screens */
    margin-left: auto; /* Align links to the left */
  }

  .navbar-contact {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align contact to the right on larger screens */
    padding: 4px 8px;
    font-size: 0.75rem; /* Smaller text for better fit on mobile and tablet */
    margin: 0 16px; /* Space around the contact box */
    width: auto;
  }

  .MuiButtonBase-root.navbar-menu-button {
    display: none;
  }
}

@media (max-width: 500px) {
  .navbar-contact {
    width: auto;
    margin-left: 0;
  }
  .navbar-contact .contact-text {
    font-size: 0.75rem;
  }
}
