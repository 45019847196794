body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.feature-cards {
  margin-top: 48px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive Design */
@media (max-width: 960px) {
  .grid-item {
    flex: 1 1 calc(50% - 10px); /* Two items per row on tablets */
  }
}

@media (max-width: 600px) {
  .grid-item {
    flex: 1 1 100%; /* Stack items vertically on mobile */
    margin: 5px 0; /* Adjust vertical spacing */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
